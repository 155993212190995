import * as React from 'react';
import Layout from '../components/layout/component';
import PageNotFoundError from '../components/page-not-found-error/component';

const PageNotFound = () => {

  const pageNotFoundTabTitle = 'Uh Oh';

  return (
    <Layout
      tabTitle={pageNotFoundTabTitle}
    >
      <PageNotFoundError />
    </Layout>
  );
};

export default PageNotFound;
