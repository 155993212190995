import * as React from 'react';
import Button from '../button/component';
import './styles.scss';

const PageNotFoundError: React.FunctionComponent = () => {
  return (
    <div className="page-not-found">
      <h1>
        (╯°□°）╯︵ ┻━┻
      </h1>
      <br />
      <p>
        Oh no. Something went wrong.
      </p>
      <div className="page-not-found__button">
        <Button name="Go Home" link="/" />
      </div>
    </div>
  );
};

export default PageNotFoundError;
